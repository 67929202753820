@import "meteogrid-timeline.css";

:root {
  --footer-height: 22pt;
  --main-color: #ac2624;
  --accent-color: #ac2624;
  --light-color: #fbeded;
  --semitransparent-color: rgba(255, 255, 255, 0.7);
  --ol-subtle-background-color: rgba(255, 255, 255, 0.4);
}


.resaltado,
.resaltado:hover {
  font-size: large;
  color: var(--main-color);
  font-weight: bold;
  text-decoration: none;
}

.resaltado-meteo,
.resaltado-meteo:hover {
  font-size: large;
  color: var(--accent-color);
  font-weight: bold;
  text-decoration: none;
}

.enlace {
  font-size: large;
  color: var(--main-color);
  font-weight: bold;
  text-decoration: underline;
}

.enlace-icono {
  font-size: large;
  color: var(--main-color);
}

.volver {
  color: var(--main-color);
  font-weight: bold;
}

.negrita-main-color {
  color: var(--main-color);
  font-weight: bold;
  vertical-align: middle;
}

.page-breadcrumb {
  display: none;
}

.prelista {
  margin-bottom: 5px;
}

.sin_espacio {
  margin-top: 0px;
  margin-bottom: 0px;
}

.lista {
  padding-top: 0pt;
  list-style: none;
}

.lista li {
  padding: 0 0 0 25px;
}

.fecha {
  float: right;
}

.derecha {
  float: right !important;
  list-style: none;
  padding-left: 3px;
  width: auto;
  justify-content: end;
}

.izquierda {
  float: left;
}

.ui-dialog-buttonset>button.fa.fa-female {
  line-height: normal;
  height: 27.65px;
}

.alto-fijo {
  height: 100%;
}

.titulo_cabecera {
  color: #fff;
  font-size: 20pt;
  font-weight: 700;
  text-align: center !important;
  padding-top: 10pt;
}

body {
  color: #4a2151;
}

select,
input {
  border: 1px var(--main-color) solid;
  color: inherit;
  box-shadow: none;
  background: white;
  line-height: 12pt;
}

select,
input[type='file'] {
  border: inherit;
}

.form-group>textarea:focus {
  box-shadow: none;
}

.form-group>input[type='text']:focus,
.form-group>input[type='password']:focus {
  border-color: var(--main-color);
  box-shadow: none;
}

.copyright {
  padding-right: 0.5em;
}

input.year {
  width: 4em;
}

input[type='date'] {
  line-height: 1.7em;
  width: 9.3em;
}

input.texto {
  width: 12em;
}

.container {
  width: 100%;
  padding: 0px 0px 0 0px;
  max-width: 100%;
}

.row {
  margin-left: 10px;
  margin-right: inherit;
}

div.row {
  margin-left: inherit;
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-header {
    float: left;
  }
}

div.page-block.graphicpage,
div.page-block {
  padding-left: 4pt;
  padding-right: 4pt;
  position: relative;
}

div.page-block.graphicpage,
div.page-block.mappage,
div.page-block.simulationpage {
  padding: 0;
}

#body-mosaicpage,
#body-graphicpage,
div.graphicpage,
div.mosaicpage {
  div.arbol {
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    width: auto;
    top: 11pt;
  }
}

#body-mosaicpage,
#body-graphicpage {
  div.arbol {
    position: fixed;
    z-index: 99;
    display: none;
    padding: 6pt;
    max-height: calc(100% - 128pt);
    overflow: auto;
    top: 103pt;
  }
}

.propiedades-capa {
  margin-left: 25pt;
  min-width: max-content;
  font-size: 10pt;
}

.accordion {
  font-family: inherit;
  font-size: 14px;
  color: inherit;
}

.accordion span {
  color: var(--main-color);
  padding-right: 5px;
}

.col-lg-12 {
  padding-top: 5px;
  padding-bottom: 5px;
}

#content {
  padding: 0;
}

#sub-footer {
  margin: 1pt;
}

#sub-footer .row.derecha {
  width: 100%;
}

@media screen and (min-width: 768px) {
  #sub-footer {
    margin-left: 4pt;
    margin-right: 4pt;
  }
}

.img-drcha {
  margin-left: 20px;
  float: right;
}

.img-izda {
  margin-right: 20px;
  float: left;
}

.img-clear {
  clear: both;
  margin: 20px;
}

#info .info_tabla table tr td {
  padding: 6pt;
  text-align: center;
}

.fa-magnifying-glass:before {
  content: '\E028';
}

.navbar-form {
  margin-bottom: 0px;
  margin-top: 0px;
}

input#id_email_address {
  width: 500px;
}

footer .col-lg-12 {
  float: left;
  margin-bottom: 0;
}

ul.nav.navbar-nav {
  padding-right: 10pt;
}

.btn {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
}

table#municipios {
  clear: both;
}

#content table.dataTable tr td,
#content table.dataTable tr th {
  padding: 0 12pt;
}

html .inspire-tree [type='checkbox'] {
  left: 22px;
  top: 1px;
}

html .inspire-tree a {
  font-size: 14px;
}

span.title {
  font-size: 14px;
}

a {
  text-decoration: none;
  color: #4a2151;
}

html .inspire-tree title {
  text-decoration: none;
  font-size: 14px;
  color: #4a2151;
}

a:hover {
  text-decoration: none;
  color: #4a2151;
}

html .inspire-tree .selected>.wholerow,
html .inspire-tree .selected>.title-wrap:hover+.wholerow {
  background: var(--light-color);
}

html .inspire-tree .title-wrap:hover+.wholerow {
  background: inherit;
}

html .inspire-tree .icon-collapse::before {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23AF8CBF%22%20d%3D%22M4%201h16q1.242%200%202.121%200.879t0.879%202.121v16q0%201.242-0.879%202.121t-2.121%200.879h-16q-1.242%200-2.121-0.879t-0.879-2.121v-16q0-1.242%200.879-2.121t2.121-0.879zM20%203h-16q-0.414%200-0.707%200.293t-0.293%200.707v16q0%200.414%200.293%200.707t0.707%200.293h16q0.414%200%200.707-0.293t0.293-0.707v-16q0-0.414-0.293-0.707t-0.707-0.293zM8%2011h8q0.414%200%200.707%200.293t0.293%200.707-0.293%200.707-0.707%200.293h-8q-0.414%200-0.707-0.293t-0.293-0.707%200.293-0.707%200.707-0.293z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-color: #fff;
  border-radius: 3px;
  margin-left: -1px;
}

html .inspire-tree .icon-expand::before {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23AF8CBF%22%20d%3D%22M4%201h16q1.242%200%202.121%200.879t0.879%202.121v16q0%201.242-0.879%202.121t-2.121%200.879h-16q-1.242%200-2.121-0.879t-0.879-2.121v-16q0-1.242%200.879-2.121t2.121-0.879zM20%203h-16q-0.414%200-0.707%200.293t-0.293%200.707v16q0%200.414%200.293%200.707t0.707%200.293h16q0.414%200%200.707-0.293t0.293-0.707v-16q0-0.414-0.293-0.707t-0.707-0.293zM12%207q0.414%200%200.707%200.293t0.293%200.707v3h3q0.414%200%200.707%200.293t0.293%200.707-0.293%200.707-0.707%200.293h-3v3q0%200.414-0.293%200.707t-0.707%200.293-0.707-0.293-0.293-0.707v-3h-3q-0.414%200-0.707-0.293t-0.293-0.707%200.293-0.707%200.707-0.293h3v-3q0-0.414%200.293-0.707t0.707-0.293z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-color: #fff;
  border-radius: 3px;
  margin-left: -1px;
}

.navbar .nav>li>a {
  color: var(--text-color);
}

.collapsing {
  float: right;
  position: absolute;
  right: 0;
  margin: 5pt;
  height: 0;
  width: 100pt;
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
}

.sb-capas {
  left: 4pt;
  top: 4pt;
}

/* quito esto porque sale hueco en el árbol de elementos saver 

div.page-block div.sidebar-pane.arbol {
  margin-top: 15px;
}
*/
div.botonera {
  width: fit-content;
}

body#body-blocks {

  div.page-block.graphicpage,
  div.page-block.mosaicpage {
    flex-grow: 1;

    div.botonera.ol-control.ol-bar,
    div.parameters {
      position: relative;
    }

    div.parameters {
      left: calc(100% - 423pt);
    }
  }
}

body#body-graphicpage,
body#body-mosaicpage,
body#body-graphicpage,
div.block-0.mosaicpage,
div.block-0.graphicpage {
  .botonera.ol-control.ol-bar.ol-top.ol-left {
    padding-top: 25pt;
  }
}

body#body-blocks div.mosaics {
  height: unset;
}

body#body-graphicpage,
body#body-mosaicpage,
body#body-graphicpage,
div.page-block.graphicpage,
div.page-block.mosaicpage {
  .botonera.ol-control.ol-bar.ol-top.ol-left .right-bar button {
    display: inline-block;
    margin: 2px 1px;
  }

}

body#body-graphicpage,
body#body-mosaicpage,
body#body-graphicpage,
div.page-block.graphicpage,
div.page-block.mosaicpage {
  .botonera.ol-control.ol-bar.ol-top.ol-left {
    display: inline-flex;
  }
}

.sb-capas button {
  border: none;
  padding: 0px;
}

div.page-block.graphicpage .sb-capas button,
div.page-block.mosaicpage .sb-capas button {
  font-size: inherit;
}

:focus {
  outline-color: var(--main-color);
}

div.formulario {
  width: 100%;
  height: calc(100% - 20px);
  padding-bottom: 19px;
  padding-left: 0pt;
  position: relative;
  font-size: 11pt;
  color: var(--main-color);
}

.sidebar {
  z-index: 10;
}

.sidebar.collapsed {
  width: 30px;
  height: 30px;
}

.sidebar:not(.collapsed) {
  padding-bottom: 56pt;
  background: transparent;
}

.collapsed .sidebar-content {
  width: 19pt;
}

.sidebar-left .sidebar-content {
  position: relative;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0px;
  right: 30px;
  top: 23pt;
}

.sidebar-tabs,
.sidebar-tabs>ul>li.active {
  height: 100%;
  background-color: transparent;
  width: 1.5em;
}

.sidebar-tabs>li,
.sidebar-tabs>ul>li {
  height: inherit;
  padding: 0px;
  line-height: 0px;
}

.sidebar-tabs>li>a,
.sidebar-tabs>ul>li>a {
  display: inline;
  padding: 0px;
  line-height: inherit;
}

@media screen and (min-width: 768px) {
  body .sidebar {
    top: 0.5em;
    /*      bottom: 6px;*/
    transition: width 500ms;
  }
}

@media screen {
  .sidebar-pane.active {
    min-width: 0pt;
  }
}

div#sidebar.sidebar.sidebar-left {
  width: auto;
}

.sidebar-content {
  background-color: rgba(255, 255, 255, 0.85);
}

body .navbar .container {
  width: 100%;
  max-width: 100%;
  display: block;
}

.dialog {
  display: none;
  font-size: 14px;
  text-align-last: auto;
  max-height: 80vh;
}

div.delete-td {
  position: relative;
  width: 25pt;
  right: auto;
  padding-left: 5pt;
  bottom: auto;
  float: right;
}

a .delete {
  color: var(--main-color);
}

a .delete:hover,
a .delete:focus {
  color: var(--accent-color);
}

#content .dialog tr td {
  padding: 2pt;
  line-height: 17pt;
  vertical-align: top;
}

#content .dialog tr[element-id] td {
  white-space: nowrap;
}

#element-info {
  overflow: auto;
  background: white;
  font-size: 14px;
}

#element-info {
  z-index: 10;
}


/* OJO Esto es delicado, verificar cambiar tamaño diálogo */

#info,
#dims-dialog {
  z-index: 0;
  padding-top: 1pt;
  padding-right: 15.5px;
  padding-left: 15.5px;
  max-width: calc(100vw - 40pt);
}

div:has(div#dims-dialog) {
  z-index: 2004;
}

.listado td:first-child {
  width: 40px;
  cursor: pointer;
}

#simulation-element-type-dialog td:first-child {
  width: auto;
}

table.data td:first-child {
  color: var(--main-color);
}

table.data {
  width: 100%;
}

#content .propiedades-capa .listado table tr td,
th {
  padding-top: 1pt;
  padding-bottom: 1pt;
}

#content table.recuadro.listado tr td,
#content table.recuadro.listado tr th {
  padding-left: 4pt;
  padding-right: 4pt;
}

#content #info table tr td,
th {
  padding-top: 1pt;
  padding-bottom: 1pt;
}

div.mapa.interactive {
  height: 100%;
}

.row>* {
  padding-right: inherit;
  padding-left: inherit;
}

/*****************************************************/

body {
  height: 100vh;
  width: 100%;
  position: relative;
}

div#wrapper {
  height: 100vh;
}

footer {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: none;
  height: var(--footer-height);
  vertical-align: bottom;
  z-index: 10;
}

.resizable-panel {
  border: 5px solid grey;
}

html .ui-widget {
  font-family: inherit;
}

html .ui-widget-content,
html .ui-widget-content a {
  color: #4a2151;
}

html .ui-widget button.fa {
  font-family: 'Font Awesome 6 Free';
}

html .ui-widget input,
html .ui-widget select,
html .ui-widget textarea,
html .ui-widget button {
  font-family: inherit;
  font-size: inherit;
}

.messages {
  position: absolute;
  margin: 30pt;
  z-index: 100000;
}

html .ui-dialog .ui-dialog-resizing {
  max-width: none;
}

.ui-dialog .ui-dialog-content {
  padding: 0;
}

@media (min-width: 768px) {
  .ui-dialog .ui-dialog-content {
    padding: 2pt;
  }
}

html .ui-dialog .ui-dialog-titlebar {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

html .ui-widget-header {
  background: var(--main-color);
}

html .ui-dialog .ui-dialog-title {
  color: white;
  font-size: 14px;
}

html .ui-dialog th {
  text-align: center;
  line-height: normal;
}

.form-dialog td:nth-child(1) {
  width: auto;
  vertical-align: top;
}

.form-dialog textarea,
.form-dialog select {
  width: 100%;
}

html .ui-dialog td {
  padding-left: 3pt;
  padding-right: 3pt;
  line-height: normal;
}

html .ui-dialog textarea.simple,
.ui-dialog select.simple {
  height: auto;
}

html .ui-dialog select.simple {
  margin-bottom: 10px;
}


/* html .ui-dialog textarea.doble{
    height:43pt;
} */

html .ui-dialog tr.model-row {
  display: none;
}

.related-title {
  font-weight: bold;
}

html .ui-dialog tr.related-model-row {
  display: none;
}

.model {
  display: none;
}

.ui-dialog-buttonpane button,
.ui-dialog-buttonpane button:focus {
  background-color: var(--main-color);
  background: var(--main-color);
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 3pt;
}

.ui-dialog-buttonpane button:hover {
  background-color: var(--accent-color);
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 3pt;
}

html .ui-dialog-buttonset {
  text-align: right;
  width: 100%;
  font-size: smaller;
}

td.data_old,
td.data_old_ {
  background: var(--light-color);
  border: 2px solid white;
  vertical-align: top;
  min-width: min-content;
  max-width: 50%;
  width: auto;
}

input[type='text'].data_new,
textarea.data_new,
select.data_new {
  border: 1pt solid var(--main-color);
}

.dialog span.data_old p {
  margin: 0 0 0;
}

.data_new {
  vertical-align: top;
  margin: 1pt;
  width: 100%;
  color: inherit;
}

textarea.data_new,
select.data_new,
input.data_new {
  margin-left: .5pt;
}

input.data_new[type=file] {
  display: none;
}

div.element_user.data_new {
  display: none;
}

.datacomments_old {
  margin-left: 0em;
}

textarea.datacomments_new {
  display: none;
}

span.datacomments_new {
  padding-left: 0.4em;
  padding-right: 0.4em;
  display: none;
}

label[name="upload"] {
  width: 80%;
  display: block;
}

label[name="upload"] i {
  background-color: var(--main-color);
  color: white;
  padding: 2px;
  border-radius: 2pt;
  vertical-align: bottom;
}

label[name="upload"] i:hover {
  background-color: var(--accent-color);
  color: white;
}

div#error.ui-dialog-content {
  font-size: 14px;
  margin: 4pt 4pt 0pt 4pt;
}

.listado button.emmet-delete,
.listado button.emmet-edit,
.listado button.emmet-clone,
.listado button.emmet-commit-create,
.listado button.emmet-edit-new,
.listado button.emmet-commit-edit,
.listado button.emmet-commit-delete {
  display: none;
}

.listado .can-edit button.emmet-delete,
.listado .can-edit button.emmet-edit,
.listado .can-commit.pending_create button.emmet-commit-create,
.listado .can-edit-new.pending_create button.emmet-edit-new,
.listado .can-commit.pending_edit button.emmet-commit-edit,
.listado .can-commit.pending_delete button.emmet-commit-delete,
.listado .can-commit button.emmet-change-remove {
  display: inherit;
}

.hidden {
  display: none !important;
}

html .ui-front {
  z-index: 2000;
}

html .ui-front.ui-front-ma-non-troppo {
  z-index: 1000;
}

body#body-mappage section#content {
  overflow: visible;
  padding: 0 0 0 0;
}

body#body-mappage section#content div.container {
  height: 100%;
  padding: 0 0 0 0;
}

body#simulation_elements section#content {
  overflow: visible;
  padding: 0 0 0 0;
}

body#listado_body section#content {
  height: calc(100% - 55pt - var(--footer-height));
  overflow: scroll;
}

section#content div.container div.mosaics {
  padding: 0 20px 0 0;
}

section#content div.container div.row {
  height: 100%;
  justify-content: left;
}

section#content {
  height: calc(100% - 51pt - var(--footer-height));
  overflow: scroll;
}

body#body-mosaicpage,
body#body-graphicpage {
  section#content {
    overflow: unset;
  }
}

@media (min-width: 768px) {
  section#content {
    height: calc(100% - var(--footer-height) - 53pt);
  }
}

@media (max-width: 767px) {
  .navbar-header {
    margin-top: inherit;
  }

  body#body section#content {
    height: calc(100% - 99pt);
  }

  .navbar-form {
    display: none;
  }

  div.contenedorcentrado {
    margin-right: inherit;
    margin-left: inherit;
    margin-top: inherit;
  }

  ul.breadcrumb:not(.derecha) :not(.active) {
    display: none;
  }

  .breadcrumb>li+li:before {
    content: none;
  }

  body#listado_body section#content {
    height: calc(100% - 53pt - var(--footer-height));
    overflow: scroll;
  }
}

.breadcrumb>li+li:before {
  color: white;
}

.breadcrumb.derecha>li+li:before {
  content: none;
}

.boton-ol {
  color: white;
  background-color: var(--main-color);
  width: 1.375em;
  height: 1.375em;
  border-radius: 2pt;
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 0.4em;
  border: none;
  padding-top: 6px;
  align-self: center;
  margin-right: 3px;
  margin-left: 3px;
}

.boton-ol-grande {
  width: 8rem !important;
}

.centrado {
  line-height: 1;
}

@media (max-width: 767px) {
  .navbar-brand img {
    max-height: 19pt;
  }
}

.navbar.navbar-default.navbar-static-top .container .lang-select form {
  position: absolute;
  bottom: auto;
  top: 60%;
  right: auto;
  left: 1%;
}

.navbar.navbar-default.navbar-static-top .container .lang-select input[type='submit'] {
  padding: inherit;
  font-size: 14px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 2px;
  line-height: normal;
}

body#body-mosaicpage #content img {
  max-width: inherit;
  height: inherit;
}

div.page-block.mappage,
div.page-block.simulationpage {
  #content img {
    max-width: inherit;
    height: inherit;
  }
}

#content div.arbol div.leyenda img {
  max-width: 310px;
}

div.page-block.mappage,
div.page-block.simulationpage {
  #content button.reset-filter img {
    max-width: 85%;
    height: auto;
  }
}

body#body-mosaicpage .titulo {
  color: var(--main-color);
  font-weight: bold;
  padding-bottom: 0;
  padding-left: 0;
}

body#body-mosaicpage .time_info {
  font-size: 90%;
  text-align: center;
}

.contenedorcentrado {
  margin-top: 30pt;
  margin-left: 100pt;
  margin-right: 100pt;
  flex-shrink: inherit;
}

.reducido {
  font-size: smaller;
}

.listado_mantenimiento {
  width: fit-content;
  max-width: 100%;
}

body#listado_body div.listado_mantenimiento button:not(.ui-button) {
  background-color: var(--main-color);
  border-color: var(--main-color);
  display: block;
  margin: 1px;
  padding: 0;
  color: #fff;
  font-size: 0.9em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
}

body#listado_body div.listado_mantenimiento button:not(.ui-button):hover {
  background-color: var(--accent-color);
}

div.listado_mantenimiento button.fa {
  width: 1.375em;
}

body#listado_body div.listado_mantenimiento button:not(.ui-button) {
  display: inherit;
}

body#listado_body tr.geom-buttons {
  display: none;
}

html .pagination {
  margin: 0 0 0 25px;
}

table.table {
  margin: 0;
}

.pagination>li.ui-state-default>a {
  background-color: var(--main-color);
  border-color: var(--main-color);
  margin: 1px;
  padding: 2px;
  color: #fff;
  font-size: 0.9em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  border: none;
  border-radius: 2px;
}

.pagination>li.ui-state-default>a:hover,
.pagination>li.ui-state-default>a:focus {
  background-color: var(--accent-color);
  color: #fff;
  outline: none;
}

.pagination>li:hover,
.pagination>li:focus {
  border: none;
  background: none;
}

div.listado_mantenimiento a.link {
  color: var(--main-color);
  background-color: inherit;
  font-weight: inherit;
}

div.listado_mantenimiento .calendar a {
  background-color: initial;
  height: auto;
}

div.listado_mantenimiento button:focus {
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
}

div.listado_mantenimiento button:hover {
  background-color: var(--accent-color);
}

div.listado_mantenimiento button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.report-parameters {
  min-width: 120pt;
}

body#planes_body #content {
  height: calc(100% - 113pt);
}

body#planes_body #content div.container {
  height: 100%;
}

body#planes {
  height: 100%;
}

body#simulaciones_body #content {
  height: calc(100% - 113pt);
}

body#simulaciones_body #content div.container,
body#simulation_elements #content div.container {
  height: 100%;
  overflow: visible;
  padding: 0;
}

body#simulaciones_body #content div.container .scroll,
body#simulation_elements #content div.container .scroll {
  height: 100%;
  overflow: auto;
  padding: 20pt;
}

td.data-cell {
  text-align: right;
}

span.fecha {
  white-space: nowrap;
}

html .ui-dialog .ui-dialog-buttonpane {
  margin-top: 0;
  padding: 0;
}

body#simulaciones_body table.files {
  display: none;
}

body#simulaciones_body table.events {
  display: none;
}

.multi-group {
  padding-bottom: 6pt;
  clear: both;
}

.listado_mantenimiento div.second,
.listado_mantenimiento div.third {
  position: relative;
  top: 15pt;
}

input[type='submit'] {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
  font-weight: bold;
  border-radius: 3pt;
  padding: 0.4em 1em;
}

.multi {
  min-width: 400pt;
  float: left;
  padding: 3pt 3pt;
}

.multi-wrapper .non-selected-wrapper .item.disabled,
.multi-wrapper .selected-wrapper .item.disabled {
  text-decoration: inherit;
}

.multi-wrapper .item {
  display: block;
  padding: 1px 10px;
}

.multi-wrapper .header {
  color: #4a2151;
}

.lista-ayuda li {
  display: flex;
}

.inline-btn {
  display: inline-flex;
}

.layer-info {
  color: var(--main-color);
  vertical-align: text-bottom;
  margin-left: 1rem;
  margin-bottom: 1pt;
  margin-top: 5px;
  padding: 0;
  font-size: 8pt;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
  background-color: transparent;
}

.layer-info:focus {
  outline-color: transparent;
}

.layer.tooltip {
  max-width: 350pt;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
}

.tooltip {
  background: #fff;
  opacity: 90%;
  border: 0.5px solid #b9b9b961;
  color: inherit;
  font-family: inherit;
  font-size: 9pt;
  line-height: 1.6em;
  border-radius: 3px;
  padding: 8px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.tooltip-arrow {
  display: none;
}

.tooltip-inner {
  background-color: white;
  color: inherit;
  font-size: 9pt;
  line-height: 1.6em;
  text-size-adjust: 100%;
  padding: 0;
  border-radius: 0;
  max-width: 400px;
  text-align: left;
}

.form-control {
  height: 18pt;
  border: 1px var(--main-color) solid;
  border-radius: 0;
}

.dataTables_wrapper .form-control {
  height: 22pt;
}

.form-control:focus {
  box-shadow: none;
}

select.form-control:focus {
  border: none;
}

select.form-control {
  border: none;
  box-shadow: none;
  color: var(--main-color);
  padding-left: 0;
}

.listado select.form-control {
  width: auto;
  font-size: 12pt;
  padding-bottom: 0;
}

.ms-container {
  background: inherit;
  width: 100%;
}

.ms-container .ms-selectable,
.ms-container .ms-selection {
  background: #fff;
  color: #555555;
  float: left;
}

.ms-container input {
  width: 100%;
}

.multiselect-botones {
  height: 2pt;
}

div.multiselect-header {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-top: 7pt;
  line-height: 12pt;
}

title.ms-title {
  display: flex;
  margin: 10px;
  font-size: medium;
  font-weight: bold;
  color: #555555;
  justify-content: center;
}

/*
html .ui-state-default,
html .ui-widget-content .ui-state-default,
html .ui-widget-header .ui-state-default,
html .ui-button,html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background: inherit;
  border: none;
}
*/

html table.dataTable .ui-icon {
  display: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: var(--light-color);
  background: white;
  color: var(--main-color);
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  background-color: var(--main-color);
}

html caption {
  font-weight: bold;
  color: var(--accent-color);
  text-align: left;
  caption-side: top;
}

div.copyright {
  float: left;
}

div.print {
  position: absolute;
  width: 31px;
  top: 0.5em;
  left: auto;
  right: 0.5em;
}

.navbar-default .navbar-toggle {
  background-color: var(--main-color);
  border: none;
}

.navbar-default .navbar-toggle .icon-bar {
  display: block;
  border-radius: 1px;
  background-color: white;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 5pt;
  margin-top: 8px;
  margin-bottom: 8px;
  background-image: none;
  border-radius: 4px;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: var(--accent-color);
}

section#inner-headline div.col-sm-6 {
  padding: 0pt;
  width: 50%;
}

button.ui-dialog-titlebar-close {
  background: var(--main-color);
  border: none;
}

button.ui-dialog-titlebar-close {
  background-image: url('/media/uploads/site/iconos/times-solid.svg');
  background-position: center;
  background-repeat: no-repeat;
}

button.ui-dialog-titlebar-close:hover {
  color: white;
  background-color: var(--accent-color);
  border: none;
}

button.ui-dialog-titlebar-close>.ui-icon {
  background-image: url('/media/uploads/site/iconos/times-solid.svg') !important;
  color: white;
  background-position: center;
}

button.ui-dialog-titlebar-close:focus {
  outline: none;
  background-color: var(--main-color);
  border: none;
}

.ui-dialog,
.ui-dialog-content {
  box-sizing: content-box;
}

ul::-webkit-scrollbar,
div::-webkit-scrollbar,
textarea::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
}

ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: white;
  border-radius: 0px;
}

ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 0px;
}

ul::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background: var(--accent-color);
  ;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

ul::-webkit-scrollbar-thumb:active,
div::-webkit-scrollbar-thumb:active,
textarea::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:active {
  background-color: var(--accent-color);
  ;
}

ul::-webkit-scrollbar-track:hover,
ul::-webkit-scrollbar-track:active,
div::-webkit-scrollbar-track:hover,
div::-webkit-scrollbar-track:active,
textarea::-webkit-scrollbar-track:hover,
textarea::-webkit-scrollbar-track:active,
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: var(--light-color);
  ;
}

a,
i,
button {
  cursor: pointer !important;
}

button.info-dataTable,
button.info-dataGraph {
  padding: 3px 5px;
}

div#info-dataTable {
  max-width: 500px;
  font-size: 14px;
}

div#info-dataTable>h4 {
  position: relative;
  left: -10px;
}

div#info-dataTable.static,
div#info-dataGraph.static {
  height: 60rem !important;
}

div#graph {
  margin-bottom: 20px;
}

div#threshold {
  position: absolute;
  z-index: 10;
  bottom: 0;
  background-color: var(--semitransparent-color);

  input {
    border: var(--light-color) 1p solid;
    background-color: transparent;
  }
}

div#calculationData_table {
  padding-bottom: 20px;
}

div#calculationData_table table td {
  padding: 3px;
}

#tab,
#tabs-Table,
#tabs-Graph {
  margin-bottom: 15px;
}

#tabs-Table .ui-tabs-panel,
#tabs-Graph .ui-tabs-panel {
  padding: 3pt 7pt;
}

td.info-data {
  background: var(--light-color);
  border: 2px solid white;
  text-align-last: center;
}

tr[name="title_h2"] td {
  text-align-last: center;
  font-size: 15px;
}

tr[name="title_h1"] td,
td[name="title_v"] {
  text-align-last: center;
  font-size: 16px !important;
}

tr[name="title_h1"] td,
tr[name="title_h2"] td,
td[name="opt_h"],
#info-dataTable span {
  color: var(--main-color);
  font-weight: 600;
}

#tabs-Table li a.ui-tabs-anchor,
#tabs-Graph li a.ui-tabs-anchor {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
}

#tabs-Table li.ui-tabs-tab,
#tabs-Graph li.ui-tabs-tab {
  padding: 0px 3px;
}

#tabs-Table li.ui-state-default,
#tabs-Graph li.ui-state-default {
  background: var(--main-color);
  color: white;
  border: 1px solid #dddddd
  /*{borderColorHeader}; */
  ;
}

#tabs-Table li.ui-state-active,
#tabs-Graph li.ui-state-active {
  background: white;
  color: var(--main-color);
}

#tabs-Table li.ui-state-hover,
#tabs-Graph li.ui-state-hover {
  background: var(--accent-color) !important;
  color: white !important;
}

#tab h4,
#tabs-Table h4,
#tabs-Graph h4 {
  position: relative;
  left: -10px;
}

#tabs-Table ul.ui-tabs-nav.ui-widget-header,
#tabs-Graph ul.ui-tabs-nav.ui-widget-header {
  background: white;
  border: none;
}

td.group_share {
  position: relative;
}

td.group_share a {
  color: var(--main-color);
  position: inherit;
  width: 100%;
}

td.group_share input[type=checkbox]~a:before {
  position: absolute;
  left: auto;
  right: -20px;
  top: 4px;
}

body .dygraph-legend {
  background-color: #fff9;
}

g.pointtext {
  display: none;
}

button#boxplot.active {
  color: var(--accent-color);
}

.container>.navbar-header,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}

#content div.container-fluid {
  padding: 0;
}

.container>.navbar-collapse {
  display: block;
  height: auto;
  padding-bottom: 0;
  overflow: visible;
  width: auto;
  border-top: 0;
  box-shadow: none;
  position: absolute;
  max-width: calc(100% - 102pt);
  right: 0;
}

div#affected-elements td {
  border-bottom: 1px solid var(--light-color);
}

div#affected-elements .total-count-by-minutes.delenda,
div#affected-elements .count.delenda,
div#affected-elements .total-count,
div#affected-elements .total-count-by-type {
  text-align: center;
}

th.time-for-minutes {
  padding: 1pt;
  font-size: smaller;
}

div.block-0 div.richtext {
  padding-top: 24pt;
}