:root {
  --tras-botonera: 27pt;
}

body.wait *,
body.wait {
  cursor: progress !important;
}

.ol-control.ol-bar .ol-toggle.ol-active button:hover,
.ol-control.ol-bar .ol-toggle.ol-active>button {
  background: var(--accent-color);
}

.ol-control.ol-bar .ol-control.ol-option-bar {
  background: rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 2px;
}

.ol-control.ol-bar.ol-right .ol-option-bar:before {
  border-color: transparent transparent transparent var(--main-color);
}

.ol-overviewmap.ol-unselectable.ol-control:not(.ol-collapsed) {
  background-color: rgba(255, 255, 255, .4);
  position: absolute;
  bottom: 100%;
  left: auto;
  right: 0em;
  height: 155px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.ol-overviewmap.ol-unselectable.ol-control.ol-collapsed {
  text-align: right;
}

.ol-attribution img {
  max-height: 20px !important;
  display: none;
}

.ol-attribution {
  bottom: 35px;
  right: 0.5em;
  max-width: none;
}

.ol-attribution.ol-unselectable.ol-control li {
  font-size: 9pt;
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #4d4d4d;
  text-shadow: none;
}

.ol.ol-attribution button {
  display: inline-block;
}

.ol-attribution.ol-logo-only {
  height: inherit;
}

.row .ol-attribution ul {
  text-align: end;
  background: none;
  width: 100%;
  font-size: 10pt;
  position: relative;
  bottom: 1em;
  margin-right: -21px;
  height: 2em;
}

.nominatim.ol-search.ol-unselectable.ol-control.ol-collapsed input {
  display: none;
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) input {
  border: none;
  background: rgba(255, 255, 255, 0.85);
  width: 16em;
  height: 2.1em;
  position: absolute;
  right: 2em;
  top: -2.5em;
  padding-right: 30px;
  color: #333;
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) ul {
  background-color: rgba(255, 255, 255, 0.85);
  top: 2em;
  width: 15.8em;
  max-height: 48.75em;
  white-space: normal;
  list-style: none;
  padding: 0pt;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 1px 20px rgba(30, 115, 190, 0);
  max-height: 15em;
  text-indent: -1pt;
  position: absolute;
  right: 0em;
  clear: both;
  cursor: pointer;
  color: #333;
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) li {
  font-size: 10pt;
  padding: 4pt;
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) li:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) a.reset {
  color: var(--main-color);
}

.nominatim.ol-search.ol-unselectable.ol-control.ol-collapsed button.reset-button {
  display: none;
}

.nominatim.ol-search.ol-unselectable.ol-control:not(.ol-collapsed) button.reset-button {
  display: block;
  position: absolute;
  right: 30px;
  background: #fff0;
  top: 0px;
  color: var(--main-color);
}

.coordSearch img {
  max-height: 100%;
}

div.coord-input.ol-visible {
  position: absolute;
  width: auto;
  right: 0em;
  left: auto;
  bottom: auto;
  top: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1px;
  padding-top: 3px;
  color: #4d4d4d;
}

.coord-input.ol-visible form>div {
  display: flex;
}

.coord-input.ol-visible .form>div {
  padding-right: 8px;
  display: flex;
}

.coord-input.ol-visible form>div#DMS>div,
.coord-input.ol-visible .form>div#DMS>div {
  display: flex;
  padding-right: 17px;
}

.coord-input.ol-visible button {
  background: none;
  color: var(--main-color);
}

.coord-input.ol-visible button:hover {
  background: none;
}

.coord-input.ol-visible div {
  padding-left: 4px;
}

.coord-input.ol-visible input {
  border: none;
  width: 5em;
  background-color: rgba(255, 255, 255, 0.7);
  padding-right: 4px;
  -moz-appearance: textfield;
}

.coord-input.ol-visible input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coord-input.ol-visible label {
  font-size: small;
}

div#DMS>div>div label {
  font-size: larger;
}

div#DMS>div>div input {
  width: 2.5em;
}

.coord-input.ol-visible select {
  width: auto;
  height: 27px;
  background-color: rgba(255, 255, 255, 0.7);
}

.coord-input.ol-visible option {
  width: auto;
  height: 2em;
  background-color: rgba(255, 255, 255, 0.7);
}

.coord-input.ol-visible i {
  position: relative;
  margin: auto;
  right: 0pt;
  color: var(--accent-color);
}

.coord-input.ol-visible button:hover i {
  color: #fff;
}

.ol-unselectable.ol-overlay.alertmsg {
  position: absolute;
  top: 20em;
  bottom: auto;
  width: 18em;
  height: auto;
  left: auto;
  right: 0.5em;
  color: #4d4d4d;
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.listado_mantenimiento .coord-input {
  display: block;
  position: relative !important;
}

.listado_mantenimiento div#projection input {
  width: 160px;
}

.listado_mantenimiento .coord-input input {
  background-color: var(--light-color) !important;
  font-size: small !important;
}

#selector-ambito .listado_mantenimiento div.second,
#selector-ambito .listado_mantenimiento div.third {
  top: auto;
}

.ui-dialog #selector-ambito .multi.flex .ms-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 400px;
}

.Nolegend-overlay {
  position: absolute;
  top: 50% !important;
  bottom: auto !important;
  width: auto !important;
  height: auto !important;
  left: auto !important;
  right: auto !important;
  color: #4d4d4d;
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.legend-overlay {
  border: none;
  cursor: pointer;
}

.ol-scale-line {
  background: rgba(255, 255, 255, 0.5);
  padding: 1px;
  right: 4pt;
  bottom: 4pt;
  left: inherit;
  line-height: 1em;
}

.ol-scale-line.unselectable {
  background: var(--semitransparent-color);
}

.ol-scale-line-inner {
  background: var(--semitransparent-color);
  height: 13pt;
  color: inherit;
  border: 2px solid var(--main-color);
  border-top: none;
}

.ol-overviewmap-box {
  border: 2px solid #4a2151;
}

.ol-overlay.attribution {
  position: absolute;
  width: 40em;
  background: none;
  left: -40em;
}

.ol-unselectable.ol-overlay.attribution.ol-visible {
  display: block;
}

.ol-control {
  padding: 1px;
}

.ol-touch.ol-control.ol-bar.ol-top.ol-left,
.ol-touch.ol-control.ol-bar.ol-top.ol-right {
  width: 31px;
}

.ol-control.ol-bar.ol-bottom.ol-right {
  top: auto;
  display: inline-grid;
  bottom: 2em;
  right: 4pt;
  background: rgba(255, 255, 255, 0.5);
}

html .ol-control.ol-bar.ol-top.ol-right,
html .ol-control.ol-bar.ol-top.ol-left,
.ol-touch .ol-control.ol-bar.ol-top.ol-left,
.ol-touch .ol-control.ol-bar.ol-top.ol-right {
  right: 4pt;
  top: 4pt;
  z-index: 10;
}

.ol-control.ol-bar .ol-rotate.ol-hidden {
  opacity: 0;
  display: none;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}



.ol-mouse-position,
.ol-visible-layer-info {
  background: var(--semitransparent-color);
  color: var(--main-color);
  width: auto;
  padding-left: 0pt;
  padding-right: 0pt;
  height: auto;
}

.ol-mouse-position {
  text-align: center;
  font-size: 10pt;
  line-height: 13pt;
  bottom: 5.5pt;
  top: inherit;
  left: 6px;
  max-width: 112pt;
}

.ol-visible-layer-info {
  font-size: 11pt;
  position: absolute;
  bottom: 5.5pt;
  top: inherit;
  left: 130pt;
  max-width: fit-content;
  padding-left: 5pt;
  padding-right: 4pt;
  z-index: 10;

  &:hover {
    height: auto;
    overflow: visible;

    p.first-true:has(+ p.first-false)::after {
      content: normal;
    }

    p.first-false {
      display: inherit;
    }
  }

  p {
    margin-bottom: 0pt;

    &.first-true:has(+ p.first-false)::after {
      content: ' \23f6';
    }

    &.first-false {
      display: none;
    }
  }
}

.listado button {
  font-size: 0.8em;
  margin-left: 0.6em;
  border-radius: 2px;
  border-style: solid;
  width: 1.375em;
  height: 1.375em;
}

.formulario .selector button,
.formulario .filtro button {
  width: 1.5em;
  height: 1.5em;
}

.reset-filter img {
  position: relative;
  top: auto;
  bottom: 4px;
  height: -webkit-fill-available;
  max-width: 85%;
  transform: scaleX(-1);
}

.ol-control button,
.listado button,
.formulario .selector button,
.formulario .filtro button {
  background-color: var(--main-color);
  border-color: var(--main-color);
  cursor: pointer;
  border: 2pt;
  display: inherit;
  color: white;
  padding: 1pt;
  text-align-last: auto;
}

:root .ol-control button:focus,
:root .ol-control button:hover,
.listado button:focus,
.listado button:hover {
  background-color: var(--accent-color);
  border-color: var(--main-color);
  box-shadow: none;
  color: white;
  outline: inherit;
}

.ol-control button:hover,
.listado button:hover {
  background-color: var(--accent-color);
}

.ol-control.ol-bar .ol-layerswitcher-popup.ol-forceopen ul.panel {
  display: block;
}

.chProj.combo.dropup {
  bottom: 0pt;
  left: 6pt;
  position: absolute;
  z-index: 10;
  color: var(--main-color);

  ul {
    background: var(--semitransparent-color);
  }

  button {
    display: block;
    color: var(--main-color);
    background: none;
    position: absolute;
    font-size: 14pt;
    bottom: -2pt;
    left: -3pt;
  }
}

#dimensions {
  .chProj {
    position: unset;
    bottom: unset;
    left: unset;
    background: var(--semitransparent-color);
    margin-bottom: 4pt;
    padding: 2pt;
    line-height: 10pt;
    max-height: unset;
    width: auto;
  }

  ul {
    position: absolute;
    padding: 2pt;
    max-height: unset;
    width: auto;
    text-align: center;
    float: right;
    right: -2px;
    background: var(--semitransparent-color);
  }
}

.combo.dropup.listshown {
  background: none;
  border: none;
}

.dropup.listshown>.dropup-content {
  display: block;
}

.dropdown.listshown>.dropdown-content {
  display: block;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
}

.combo>form,
.combo>ul {
  position: relative;
  bottom: 18pt;
  left: 6pt;
  display: none;
}

.combo ul {
  margin: auto;
  padding: 3pt;
  font-size: 10pt;
}

.combo ul>li {
  display: block;
  padding-left: 2pt;
  padding-right: 2pt;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  color: var(--main-color);
}

.combo ul>li.select {
  background: var(--main-color);
  color: rgba(255, 255, 255, 0.85);
}

.combo ul>li:hover {
  background-color: var(--accent-color);
  color: rgba(255, 255, 255, 0.85);
}

div>input.combo {
  line-height: 12pt;
}

div.combo>span.fa.fa-chevron-down {
  position: relative;
  left: -13pt;
  top: 1pt;
  color: var(--main-color);
}

div.combo ul.dropdown-content {
  position: absolute;
  bottom: auto;
  left: auto;
  max-height: 195pt;
  width: 134pt;
  overflow: auto;
  z-index: 10000;
}

.seleccionable,
.seleccionable input {
  cursor: pointer !important;
}

#dimensions .chProj>form {
  top: 2pt;
  left: 0pt;
  background-color: unset;
}

div.combo-coord div.dropdown {
  position: fixed;
  z-index: 999999
}

div.combo-coord>div.chProj>ul {
  bottom: auto;
  left: -4px;
  width: auto;
}

div#projection>input {
  width: 180px;
}

div#municipio {
  display: inline-block;
}

div#municipio input {
  width: 134pt;
}

table td input.combo {
  width: 100%;
}

div.data_new .combo ul {
  position: absolute;
  width: 100%;
  max-height: 65pt !important;
  webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 8px var(--light-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 8px var(--light-color);
  min-width: 100pt;
}

.ol-layerswitcher-popup button,
.layer-switcher button {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  width: 1.38em;
  height: 1.38em;
}

.ol-layerswitcher-popup button {
  background-image: url(/media/uploads/site/fondos.svg);
}

.layer-switcher button {
  background-image: url(/media/uploads/site/capas_blanco.svg);
}

.ol-layerswitcher-popup ul {
  padding-left: 0em;
}

.ol-control.ol-bar .ol-control.ol-layerswitcher-popup {
  text-align: left;
}

.ol-control.ol-bar .ol-control.ol-layerswitcher-popup .panel {
  position: absolute;
  right: 1.4em;
  top: 0pt;
  z-index: 999999999;
}

.ol-zoom-extent button {
  background-image: url(/media/uploads/site/world.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.ol-layerswitcher-popup li:hover {
  background: var(--accent-color);
}

.ol-layerswitcher-popup li {
  font-family: inherit;
  color: var(--accent-color);
}

.ol-layerswitcher-popup li.select.ol-visible {
  background-color: var(--main-color);
}

.ol-layerswitcher-popup li.ol-visible {
  background-color: var(--main-color);
}

.ol-control img {
  padding: 1px;
}

.vardata.simple>td>.data_new {
  width: calc(100% - 29pt);
}

.vardata.simple>td .input {
  width: calc(100% - 29pt);
}

.map-animation {
  top: 100px;
}

#map-animation {
  padding-right: 0em;
}

#map-animation>div {
  display: inline-flex;
  padding: 0.25rem;
}

.ol-layerswitcher-popup li.ol-visible {
  background-color: var(--main-color);
}

#map-animation>div>label {
  min-width: 120px;
  margin-right: 1rem;
  color: var(--main-color);
  font-weight: bold;
  display: inline-flex;
}

#map-animation>div>input {
  width: 90pt;
  margin-left: 0.25rem;
  display: inline-flex;
  border: 1px var(--main-color) solid;
}

#map-animation>div>input#interval {
  width: 40pt;
}

.vis-panel.vis-center,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border-left-style: inherit;
  border-right-style: inherit;
}

div.calendar {
  background: var(--semitransparent-color);
  position: absolute;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1;
  font-size: 9pt;
}

.mapa.interactive {
  div.calendar {
    top: 24pt;
    right: var(--tras-botonera);
  }

  .time-selector {
    top: 0;
    right: var(--tras-botonera);
  }
}

.time-selector {
  position: fixed;
  z-index: 15;
  text-align: left;
  left: 27pt;
  right: var(--tras-botonera);
  background: var(--semitransparent-color);
  line-height: 0.5em;
  width: auto;
}

div.page-block.graphicpage div.time-selector,
div.page-block.mosaicpage div.time-selector {
  left: 10pt;
  right: 10pt;
  top: 1pt;
}

div#dimensions {
  position: absolute;
  right: var(--tras-botonera);
  top: 6.15em;
  font-size: 9pt;
  color: var(--main-color);
  text-align: right;
  pointer-events: auto;
  z-index: 2;
}

div#dimensions div.combo span {
  margin: 10px;
}

body#body-mosaicpage div.page-block div#dimensions {
  right: 315pt;
  top: 33pt;
  display: inline-flex;
  font-size: 11pt;
}

div.page-block.mosaicpage div#dimensions span.dim_value {
  font-size: 13px;
}

div.page-block.mosaicpage div#dimensions>div {
  padding-left: 5pt;
}

div.page-block.mosaicpage div#dimensions .combo ul {
  background: white;
  border: 1px solid var(--main-color);
}



.report-parameters div.calendar {
  position: relative;
  float: right;
}

div.page-block>div.calendar {
  grid-column-start: 2;
  grid-column-end: 4;
  border: none;
  right: var(--tras-botonera);
  top: 2.8em;
  padding-left: 2pt;
  padding-right: 2pt;
  width: auto;
}

.calendar>input {
  grid-column: 1;
  border: none !important;
  box-shadow: none;
  font-size: 9pt;
  font-weight: 300;
  color: var(--main-color);
  text-align: right;
  padding: 0;
  background-color: transparent !important;
  width: 70pt;
}

.calendar>a.input-button {
  align-self: center;
}

#calendar-icon {
  color: var(--main-color);
  padding-left: 2pt;
  padding-right: 2pt;
  grid-column: 2;
}

div.page-block.graphicpage div.calendar,
div.page-block.mosaicpage div.calendar {
  right: 5pt;
  z-index: 99;
  width: auto;
}

.model_carpeta,
.model_leyenda,
.model_selector {
  display: none;
}

div.leyenda {
  padding-left: 24pt;
  padding-bottom: 6pt;
}

div.disabled {
  opacity: 0.5;
}

div.folder {

  div.leyenda,
  div.selector,
  div.formulario {
    padding-left: 9pt;
  }
}

div.folder-toggler {
  color: var(--main-color);
  margin-bottom: 3pt;

  &:not(.open) {
    &:after {
      content: '...';
    }

    opacity: 90%;
  }

}

span.celda {
  width: 200pt;
  height: 200pt;
}


div.selector {
  padding-left: 12pt;
}

div.cambios.mostrar-capa.selector {
  padding-left: 0;
}

div.propedades-capa div-propiedades-capa {
  margin-left: 15pt;
}

div.folder {
  padding-left: 8pt;
  font-weight: normal;
  margin-bottom: 5px;

  div.folder {
    padding-left: 13pt;
  }
}

.ol-unselectable.ol-overlay.slide-left.menu.ol-visible {
  background-color: rgba(255, 255, 255, 0.85);
  left: 5pt;
  height: auto;
  bottom: 3em;
  box-shadow: none;
  top: 24pt;
}

#LAYER .titulo,
#REFERENCE .titulo {
  font-weight: bold;
  color: var(--main-color);
}

div.folder,
div.selector {
  font-size: 11pt;
  line-height: 1.2em;
}

.filtro .selector.riesgo {
  padding-left: unset;
  font-size: 12px;
  padding-top: 3px;
}

.calendar-filter {
  display: inline-flex
}

.calendar-filter.hasTime input {
  width: 84pt;
}

.calendar-filter input {
  width: 62pt;
  line-height: 15pt;
  border: none;
  padding: 2px 5px;
}

.calendar-filter input:focus {
  border: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px var(--light-color);
}

.filtro input {
  font-size: 12px;
}

.filtro a {
  color: var(--main-color);
}

div.mosaics {
  position: relative;
  margin-top: 2pt;
  overflow: scroll;
  width: calc(100%);
}

div.mosaics tbody tr td:first-child {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  left: 0;
  background: #FFF;
  /* border-right: 1px solid #CCC; */
  z-index: 1;
}

div.mosaics.flex {
  flex-wrap: wrap;
  display: flex;
}

div.mosaics.flex table {
  margin-right: 20pt;
}

div.graphicpage,
div.mappage {
  div.list-info_grafica {
    position: relative;
    top: 26pt;
    overflow: scroll;
    width: calc(100vw - 10px);
    padding-right: 0;
    padding-left: 0;
  }
}

body .modelo {
  display: none;
}

section#content div.container div.row.page-block.graphicpage {
  padding-top: 22pt;
  padding-left: 7pt;
  padding-right: 7pt;
}

div.page-block.graphicpage .nomodelo {
  position: relative;
}

span.highlight {
  color: red;
}

.ol-layerswitcher-popup button {
  float: none;
}

.ol-notification {
  z-index: 9;
  position: fixed;
  bottom: var(--footer-height);
}

.ol-notification>div,
.ol-notification>div:hover {
  background-color: var(--main-color);
}

.slidecontainer {
  width: 75px;
  margin-bottom: 4px;
  padding-left: 5.5px;
  position: relative;
  margin-top: 5px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3pt;
  border: none;
  background: var(--main-color);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 15px;
  border-radius: 0%;
  background: var(--main-color);
  cursor: pointer;
  border-block-color: white;
}

.slider::-moz-range-thumb {
  width: 5px;
  height: 15px;
  border-radius: 0%;
  background: var(--main-color);
  cursor: pointer;
  border-block-color: white;
}

.etiqueta {
  width: 30px;
  height: 18px;
  position: absolute;
  z-index: 9;
  background-color: var(--main-color);
  color: white;
  font-size: 12px;
  text-align: center;
  padding-top: 0px;
  top: -34px;
}

.etiqueta:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 8;
  background-color: var(--main-color);
  position: relative;
  left: 9px;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.area.ol-control i {
  font: icon;
  font-size: 75%;
}

div.parameters {
  position: absolute;
  top: 29pt;
  width: auto;
  display: inline-flex;
  bottom: auto;
  left: auto;
  right: 140pt;
}

body#body-mosaicpage {
  div.mosaics {
    height: calc(100vh - 128pt);
    top: 49pt;
  }

  div.parameters {
    top: 23pt;
  }
}

body#body-graphicpage,
div.block-0.graphiccpage {
  div.list-info_grafica {
    top: 49pt;
  }
}

body#body-graphicpage {
  div.list-info_grafica {
    height: calc(100vh - 188px);
  }
}

div.parameters>div {
  margin: 3px;
  color: var(--main-color);
  z-index: 99;
  font-size: 11pt;
  padding-left: 13pt;
}

div.parameters>div>input {
  width: 3em;
  height: 16pt;
  border: none !important;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
  color: var(--main-color);
  text-align: center;
  padding: 0;
  background-color: transparent !important;
}

div.parameters>div>ul {
  z-index: 10000;
  background: white;
  border: 1px solid var(--main-color);
  width: 35pt;
  display: none;
}

div.parameters>div>ul>li {
  display: block;
  text-align: center;
  margin: 3px;
  line-height: 14pt;
  font-size: 13px;
}

div.parameters>div>ul>li:hover {
  background-color: var(--accent-color);
  color: white;
}

ul#interval-list {
  position: relative;
  left: auto;
  right: -33pt;
}

ul#iteration-list {
  position: relative;
  left: auto;
  right: -25pt;
}

i.fas {
  cursor: pointer !important;
}

.fa-left {
  position: relative;
  left: -5px;
}

div.parameters>div:focus>ul {
  display: block;
}

div.parameters>div>ul>li.select {
  background-color: var(--main-color);
  color: white;
}

.ms-container .ms-list.ms-focus {
  border: 1px solid #ccc !important;
  transition: border linear 0.2s, box-shadow linear 0.2s !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  outline: 0;
  outline: thin dotted \9;
}

.ms-container {
  /*
  width: 45vw !important;
  */
  background: none !important;
  position: relative;
  top: -8pt;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  background-color: var(--main-color);
}

.ms-container a {
  background: none;
  color: white !important;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 1.3em;
}

.ms-container a {
  background-color: var(--main-color);
  border-color: var(--main-color);
  display: block;
  margin: 1px;
  padding: 6px !important;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.2em !important;
  width: 1.2em !important;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
  font-size: medium !important;
}

.ms-container .ms-list {
  border: 1px var(--main-color) solid !important;
  border-radius: unset !important;
  height: 100pt;
}

.ms-container .ms-selectable button.ol-control {
  position: relative;
  top: 80px;
  margin-left: auto;
  left: 25px;
}

.ms-container button.ol-control:hover {
  background: var(--accent-color) !important;
}

.ms-container .ms-selection {
  float: left !important;
  right: -29px;
  left: auto;
  position: relative;
}

.ms-container .ms-selection button.ol-control {
  position: relative;
  top: 135px;
  left: -25px;
}

.dialog .ms-container button>a {
  padding-left: 5px;
}

.ms-container .ms-selection a {
  position: relative;
  top: 70pt;
  left: -25px;
}

.ms-container .ms-selectable a {
  position: relative;
  top: 50pt;
  margin-left: auto;
  left: 25px;
}

div#pointbycoords-dialog .data select {
  width: auto;
  border: 1px var(--main-color) solid;
}

.ol-touch .ol-control button {
  font-size: 1.0em;
}

.ol-ctx-menu-container {
  color: var(--main-color);
}

.ol-ctx-menu-container li:hover {
  background-color: var(--main-color) !important;
}

.ol-ctx-menu-container li:not(.ol-ctx-menu-separator) {
  color: var(--main-color);
  width: 100%;
}

.ol-ctx-menu-container li>span {
  padding-left: inherit;
  font-family: inherit;
}


/* .ol-ctx-menu-container li.ol-ctx-menu-zoom-in,
.ol-ctx-menu-container li.ol-ctx-menu-zoom-out, 
.ol-ctx-menu-container li.ol-ctx-menu-zoom-in:hover,
.ol-ctx-menu-container li.ol-ctx-menu-zoom-out:hover {
  background-image: none;
} */

.legend-dialog {
  padding: 0;
  background: #e6e6e682;
}

.legend-dialog .legend {
  padding: 0;
  margin-top: 2px;
  float: right;
}

td img.no-legend {
  margin-right: 100px;
}

body li[custom_legend="true"]>div>a.title::after {
  content: " \f430";
  font-family: "Font Awesome 6 Brands";
  color: var(--main-color);
}

div.graph.js-plotly-plot {
  min-height: 200pt;
}

#info>div.graph.js-plotly-plot {
  min-height: 150pt;
  height: 25%;
}

#info.subplots_1>div.graph.js-plotly-plot {
  height: 100%;
}

#info.subplots_2>div.graph.js-plotly-plot {
  height: 50%;
}

#info.subplots_3>div.graph.js-plotly-plot {
  height: 33.3%;
}

#info.subplots_info_1.subplots_1>div.graph.js-plotly-plot {
  height: calc(100% - 24pt);
}

#info.subplots_info_1.subplots_2>div.graph.js-plotly-plot {
  height: calc(50% - 12pt);
}

#info.subplots_info_1.subplots_3>div.graph.js-plotly-plot {
  height: calc(33.3% - 8pt);
}

#info.subplots_info_2.subplots_1>div.graph.js-plotly-plot {
  height: calc(100% - 48pt);
}

#info.subplots_info_2.subplots_2>div.graph.js-plotly-plot {
  height: calc(50% - 24pt);
}

#info.subplots_info_2.subplots_3>div.graph.js-plotly-plot {
  height: calc(33.3% - 16pt);
}

ul.flex-direction-nav li {
  line-height: 30pt;
}